import React from 'react';
import { Field } from 'formik';
import Select from "react-select";
import classnames from "classnames";
import "./forms.scss";

const DropDown = (props) => {
    const { label, name, options, required, ...rest } = props;

    const ifRequired = () => {
        if (required == true) {
            return <span className="required">*</span>
        }
    }

    return (
        <Field name={name} {...rest} >
            {({ form, meta }) => (
                <div className={classnames("partie-form__field-container", {
                    'partie-form__field-container--error': meta.error && meta.touched
                })}>
                    <label className="partie-form__label" id={`${name}-label`} htmlFor={name}> {label} {ifRequired()} </label>
                    <Select
                        aria-labelledby={`${name}-label`}
                        classNamePrefix="partie-form__select"
                        inputId={name}
                        options={options}
                        onChange={(e) => {
                            form.setFieldValue(name, e.value);
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                                ...theme.colors,
                                primary: "#6e4aff",
                                primary25: "#6e4aff40",
                            },
                        })}
                    />
                    {(meta.error && meta.touched) && (
                        <span className="partie-form__field-caption">{meta.error}</span>
                    )}
                </div>
            )}
        </Field>
    );
};

export default DropDown;